import IImportTableStructure from '../import-table/IImportTableStructure';
import { notificationError } from '../../../Notifications/callNotifcation';
import ITableCellTexture from '../../graphic/table/cells/ITableCellTexture';
import { TableGridMap } from '../../graphic/table/TableGridMap';
import { Token, TokenFormat, TokenType } from '../mext/parser';
import {
	FontFamily, FontSize, Model, TextAlign, 
} from '../mext/editor/types';
import Utils from '../../utils/impl/Utils';
import IGraphic from '../../graphic/IGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import IComponentTreeMutator from '../../component-tree/IComponentTreeMutator';
import IComponentTree from '../../component-tree/IComponentTree';
import ILayeredComponentTree from '../../component-tree/ILayeredComponentTree';
import MousePositionObserver from '../../utils/observers/MousePositionObserver';
import Dependent from '../../utils/dependent/Dependent';
import SketchComponentType from '../../components/SketchComponentType';
import GraphicType from '../../graphic/GraphicType';
import IGraphicStructure from '../../graphic/IGraphicStructure';
import ITableGraphicTexture from '../../graphic/table/ITableGraphicTexture';
import ITableComponentTexture from '../../components/table/ITableComponentTexture';
import IComponentStructure from '../../components/IComponentStructure';
import TableComponent from '../../components/table/TableComponent';
import TableGraphic from '../../graphic/table/TableGraphic';
import IDescartesPosition from '../../utils/IDescartesPosition';
import IComponentInjectionParams from '../../component-injector/IComponentInjectionParams';
import SpatialAreaTree from '../spatial-quadrants/spatial-tree/SpatialAreaTree';
import ComponentOrganizer from '../component-organizer/ComponentOrganizer';
import SketchStructureStabilizer from '../mutation-observer/SketchStructureStabilizer';
import ComponentFocusObserver from '../../utils/observers/ComponentFocusObserver';

export interface IHTMLTableImporterDependencies {
	spatialTree: SpatialAreaTree,
	componentOrganizer: ComponentOrganizer,
	sketchStabilizer: SketchStructureStabilizer,
	mousePositionObserver: MousePositionObserver;
	componentFocusObserver: ComponentFocusObserver,
	componentTree: IComponentTreeMutator & IComponentTree & ILayeredComponentTree;
}

interface IStylesHTML { background: string; tokens: Token[] }

class HTMLTableImporter extends Dependent<IHTMLTableImporterDependencies> {
	private readonly STYLE_COLOR = 'color';
	private readonly STYLE_TEXT_ALIGN = 'text_align';
	private readonly STYLE_FONT_WEIGHT = 'font_weight';
	private readonly STYLE_FONT_STYLE = 'font_style';
	private readonly STYLE_FONT_SIZE = 'font_size';
	private readonly STYLE_FONT_FAMILY = 'font_family';

	private readonly EMPTY_CELL_VALUE = '';

	private readonly postInjectListeners: ((tableComponent: TableComponent) => void)[];

	constructor() {
		super();
		this.postInjectListeners = [];

		this.addPostInjectListener(() => {
			setTimeout(this.dependencies.componentOrganizer.sync, 0);
			setTimeout(this.dependencies.componentOrganizer.sync, 100);
			setTimeout(this.dependencies.componentOrganizer.sync, 200);
			setTimeout(this.dependencies.componentFocusObserver.sync.bind(this), 100);
			setTimeout(this.dependencies.sketchStabilizer.stopUserAction, 200);
		});
	}

	/**
	 * Вставляет таблицы в приложение на основе переданной HTML-строки.
	 * Метод парсит входящую строку, извлекает таблицы и преобразует их в
	 * структуру, которую можно использовать в приложении. Если таблицы не найдены,
	 * выводится уведомление об ошибке.
	 * @param htmlString HTML строка таблицы.
	 */
	public importTable = (htmlString: string) => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, 'text/html');

		// Извлекаем таблицы из переданного HTML
		const tables = doc.querySelectorAll('table');
		if (tables.length === 0) {
			notificationError('Вставка таблицы.', 'В буффере нет таблиц.');
			return;
		}

		const tableStructures: IImportTableStructure[] = [];
		tables.forEach((tableElement) => {
			const tableStructure = this.convertHTMLTableToStructure(tableElement);
			tableStructures.push(tableStructure);
		});

		// TODO сделать вставку больше чем одной таблицы
		this.injectTable(tableStructures[0]);
	};

	private injectTable = (tableStucture: IImportTableStructure) => {
		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();

		const { rowCount, columnCount, cells } = tableStucture;

		if (cells.length < 1) {
			notificationError(
				'Вставка таблицы.',
				'Вставляемая таблица не имеет ячеек с текстом.',
			);
			return;
		}

		const parentComponent = this.dependencies.componentTree.getRootComponent();
		const mousePosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		const injectionParams = this.calculateComponentInjectionParams(mousePosition);
		const injectionPageGraphic = parentComponent.getGraphics()[injectionParams.componentOffset];
		const pageGlobalPosition = injectionPageGraphic.getGlobalPosition();

		const { paddingLeft, paddingRight } = injectionPageGraphic.getTexture();

		const graphic: IGraphicStructure<ITableGraphicTexture> = {
			id: graphicId,
			type: GraphicType.TABLE,
			frame: {
				width: 601,
				height:
          injectionPageGraphic.getFrameConfiguration().width
          - paddingLeft
          - paddingRight,
				layer: 0,
				x: paddingLeft,
				y: mousePosition.y - pageGlobalPosition.y,
				rotate: 0,
			},
			offset: injectionParams.componentOffset,
			texture: {
				rowCount,
			},
		};

		const tableStructure: IComponentStructure<ITableComponentTexture> = {
			id: componentId,
			type: SketchComponentType.TABLE,
			offset: 0,
			graphics: [graphic],
			texture: {
				startRows: [0],
				cells,
				borderColor: 'black',
				columnMultipliers: new Array(columnCount).fill(1),
				rowMultipliers: new Array(rowCount).fill(1),
			},
			components: null,
		};

		let component: TableComponent;
		this.dependencies.componentTree.executeMutations((tools) => {
			component = tools.componentFactory.createComponent<TableComponent>(tableStructure);
			const graphicSequence = new Map<IGraphic, TableGraphic>();

			tableStructure.graphics?.forEach((graphicStructure) => {
				const graphic = tools.graphicFactory.createGraphic<TableGraphic>(
					GraphicType.TABLE,
					component,
				);
				graphic.setStructure(() => graphicStructure);

				component.appendGraphic(graphic);

				if (tableStructure.offset === null) {
					throw new ManipulatorError(
						'the table structure does not have an offset',
					);
				}

				const pageNumber = tableStructure.offset + graphicStructure.offset;
				const lastPageLayerGraphic = tools.componentLayers.getLastLayerGraphicFromPage(pageNumber);

				graphicSequence.set(lastPageLayerGraphic, graphic);
			});

			tools.mutator.mutateByAppendComponent(parentComponent, component);

			graphicSequence.forEach((tableGraphic, prevGraphic) => {
				tools.mutator.mutateByChangeLayer(prevGraphic, tableGraphic);
			});

			this.callPostInjectListeners(component);
		});
	};

	private addPostInjectListener(listener: (tableComponent: TableComponent) => void) {
		this.postInjectListeners.push(listener);
	}

	private callPostInjectListeners = (tableComponent: TableComponent) => {
		this.postInjectListeners.forEach((listener) => listener(tableComponent));
	};

	/**
	 * Конвертирует HTML таблицу в структуру таблицы.
	 * @param table HTML элемент таблицы.
	 */
	private convertHTMLTableToStructure = (table: HTMLTableElement): IImportTableStructure => {
		const { rows } = table;

		const columnCount = Math.max(...Array.from(rows).map((row) => row.cells.length));
		const rowCount = rows.length;

		const tableStructure: IImportTableStructure = {
			cells: [],
			columnCount,
			rowCount,
		};

		const cells: ITableCellTexture[][] = [];
		const cellsGrid: TableGridMap = [];

		let currentRowsIndex = 0;
		for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
			const row = rows[currentRowsIndex];
			if (cells[rowIndex] === undefined) {
				cells[rowIndex] = [];
			}
			let currentCellsIndex = 0;
			for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
				const cellElement: HTMLTableCellElement = row.cells[currentCellsIndex];
				if (cellElement) {
					// Пропускаем уже занятые ячейки
					while (cellsGrid[rowIndex] && cellsGrid[rowIndex][columnIndex]) {
						columnIndex++;
					}

					const cellValue = cellElement.textContent ? cellElement.textContent.trim() : this.EMPTY_CELL_VALUE;
					const cellTexture = this.getDefaultCellTexture(cellValue, rowIndex, columnIndex);
					cellTexture.rowSpan = cellElement.rowSpan;
					cellTexture.columnSpan = cellElement.colSpan;

					const { background, tokens } = this.getStylesFromHTML(cellTexture, cellElement);
					cellTexture.background = background;
					cellTexture.content.tokens = tokens;

					cells[rowIndex][columnIndex] = cellTexture;

					// Построение TableGridMap (см. в описании типа).
					for (let currentRow = rowIndex; currentRow < rowIndex + cellElement.rowSpan; currentRow++) {
						if (!cellsGrid[currentRow]) cellsGrid[currentRow] = [];
						for (let currentColumn = columnIndex; currentColumn
						< columnIndex + cellElement.colSpan; currentColumn++) {
							if (!cellsGrid[currentRow][currentColumn]) {
								cellsGrid[currentRow][currentColumn] = cellTexture.id;
							}
						}
					}
				}
				currentCellsIndex++;
			}
			currentRowsIndex++;
		}

		this.convertEmptyToEmptyCells(cells, columnCount, cellsGrid);
		tableStructure.cells = this.flatCells(cells);

		return tableStructure;
	};

	/**
	 * Извлекает стили из HTML-элемента таблицы и применяет их к текстовым токенам.
	 * Метод анализирует инлайновые стили заданной ячейки таблицы,
	 * обновляет цвет фона, цвет текста, жирность, курсив, размер шрифта,
	 * семью шрифтов и выравнивание текста для токена, представляющего текст ячейки.
	 * @param cell Объект, представляющий текстуру ячейки таблицы,
	 * содержащий информацию о стилях ячейки.
	 * @param cellElement HTML-элемент ячейки таблицы, из которого будут извлечены стили.
	 */
	private getStylesFromHTML = (
		cell: ITableCellTexture,
		cellElement: HTMLTableCellElement,
	): IStylesHTML => {
		const styledCell = { ...cell };
		const tokens = [...cell.content.tokens];
		const cellTextToken = tokens[0];

		const inlineStyles = this.parseInlineStyles(cellElement);

		// Проверяем цвет заднего фона
		if (cellElement.style.backgroundColor) {
			styledCell.background = cellElement.style.backgroundColor;
		}

		// Проверяем цвет текста
		if (inlineStyles[this.STYLE_COLOR] !== 'transparent' && inlineStyles[this.STYLE_COLOR]) {
			cellTextToken.color = inlineStyles[this.STYLE_COLOR];
		}

		// Проверяем жирность текста
		if (inlineStyles[this.STYLE_FONT_WEIGHT] === 'bold' || inlineStyles[this.STYLE_FONT_WEIGHT] >= '700') {
			cellTextToken.format = TokenFormat.Bold;
		}

		// Проверяем курсив
		if (inlineStyles[this.STYLE_FONT_STYLE] === 'italic') {
			cellTextToken.format = TokenFormat.Italic;
		}

		// Проверяем на жирный курсив
		if ((inlineStyles[this.STYLE_FONT_WEIGHT] === 'bold' || inlineStyles[this.STYLE_FONT_WEIGHT] >= '700')
			&& inlineStyles[this.STYLE_FONT_STYLE] === 'italic') {
			cellTextToken.format = 0b00000011 as TokenFormat;
		}

		// Проверяем размер шрифта
		if (inlineStyles[this.STYLE_FONT_SIZE]) {
			const fontSize = inlineStyles[this.STYLE_FONT_SIZE];

			// Проверяем, соответствует ли значение одному из значений в FontSize
			const fontSizeEnum = Object.entries(FontSize).find(
				([_, value]) => value === fontSize,
			);

			if (fontSizeEnum) {
				cellTextToken.fontSize = fontSize as FontSize;
			}
		}

		// Семейство шрифтов
		if (inlineStyles[this.STYLE_FONT_FAMILY]) {
			const fontFamily = inlineStyles[this.STYLE_FONT_FAMILY]
				.split(',')[0]
				.replace(/["']/g, '');

			// Проверяем, соответствует ли значение одному из значений в FontFamily
			const fontFamilyEnum = Object.entries(FontFamily).find(
				([_, value]) => value === fontFamily,
			);

			if (fontFamilyEnum) {
				cellTextToken.fontFamily = fontFamily as FontFamily;
			}
		}

		// Выравнивание текста
		if (inlineStyles[this.STYLE_TEXT_ALIGN]) {
			cellTextToken.textAlign = inlineStyles[this.STYLE_TEXT_ALIGN] as TextAlign;
		}

		return { background: styledCell.background, tokens };
	};

	/**
	 * Парсит инлайновые стили элемента и его потомков.
	 * Запускает рекурсивный процесс для извлечения всех стилей из заданного элемента.
	 * @param cell Элемент, из которого необходимо извлечь инлайновые стили.
	 */
	private parseInlineStyles = (cell: HTMLElement): { [key: string]: string } => {
		const styles: { [key: string]: string } = {};

		// Запуск рекурсивной проверки стилей на элементе и его потомках
		this.recursiveParseStyles(cell, styles);

		return styles;
	};

	/**
	 * Рекурсивно извлекает инлайновые стили из элемента и его дочерних элементов.
	 * Считывает стили из атрибута 'style' и проверяет на наличие определенных тегов
	 * для установки дополнительных стилей.
	 * @param element Элемент, из которого будут извлечены инлайновые стили.
	 * @param styles Объект, в который будут добавлены найденные стили.
	 */
	private recursiveParseStyles = (element: HTMLElement, styles: { [key: string]: string }): void => {
		// Считывание инлайновых стилей элемента
		const inlineStyle = element.getAttribute('style');

		if (inlineStyle) {
			const rules = inlineStyle.split(';');
			rules.forEach((rule) => {
				const [key, value] = rule.split(':').map((s) => s.trim());
				if (key && value) {
					if ((key === this.STYLE_COLOR || key === this.STYLE_FONT_FAMILY) && element.textContent?.trim()) {
						styles[key] = value;
					}
					if (key !== this.STYLE_COLOR && key !== this.STYLE_FONT_FAMILY) {
						styles[key] = value;
					}
				}
			});
		}

		// Проверка тегов <b>, <i>, <strong>, <em> через инлайновые стили
		this.applyFontStylesBasedOnTags(element, styles);

		// Рекурсивная проверка дочерних элементов
		element.childNodes.forEach((child) => {
			if (child.nodeType === 1) {
				this.recursiveParseStyles(child as HTMLElement, styles);
			}
		});
	};

	/**
	 * Проверяет и добавляет стили шрифта на основе HTML-тегов.
	 * Устанавливает жирное или курсивное начертание в зависимости от наличия определенных тегов.
	 * @param element Элемент, который необходимо проверить на наличие тегов стилей.
	 * @param styles  Объект, в который будут добавлены найденные стили.
	 */
	private applyFontStylesBasedOnTags = (element: HTMLElement, styles: { [key: string]: string }): void => {
		// Проверка тегов <b>, <strong> и добавление жирного начертания
		if (element.nodeName === 'B' || element.nodeName === 'STRONG') {
			styles[this.STYLE_FONT_WEIGHT] = 'bold';
		}
		// Проверка тегов <i>, <em> и добавление курсива
		if (element.nodeName === 'I' || element.nodeName === 'EM') {
			styles[this.STYLE_FONT_STYLE] = 'italic';
		}
	};

	/**
	 * Возвращает текстуру ячейки по умолчанию.
	 * @param value Текст в ячейке.
	 * @param rowIndex Позиция ячейки по оси Y.
	 * @param columnIndex Позиция ячейки по оси X.
	 */
	private getDefaultCellTexture = (
		value: string,
		rowIndex: number,
		columnIndex: number,
	): ITableCellTexture => {
		const model = this.getDefaultModel(value);
		const cellTexture: ITableCellTexture = {
			id: Utils.Generate.UUID4(),
			content: model,
			background: '#ffffff',
			row: rowIndex,
			column: columnIndex,
			rowSpan: 1,
			columnSpan: 1,
		};
		return cellTexture;
	};

	/**
	 * Возвращает из двумерного массива текстур ячеек линейный массив.
	 * @param cells Двумерный массив ячеек, отражающий структуру таблицы.
	 */
	private flatCells = (cells: (ITableCellTexture | null)[][]): ITableCellTexture[] => cells
		.map((rowCells) => rowCells
			.filter((cell) => cell !== null) as ITableCellTexture[]).flat();

	/**
	 * Заполняет пустующие (не те, которые занимают ячейки в объединении, а с полным отсутствием в ней ячейки)
	 * координаты таблицы.
	 * @param cells Матрица текстур ячеек таблицы с пустующими координатами.
	 * @param columnCount Количество колонок.
	 * @param cellsGrid Карта ячеек (см. описание типа).
	 */
	private convertEmptyToEmptyCells = (
		cells: (ITableCellTexture | null)[][],
		columnCount: number,
		cellsGrid: TableGridMap,
	) => {
		for (let rowIndex = 0; rowIndex < cellsGrid.length; rowIndex++) {
			// Обязательно `columnIndex < columnCount` для заполнения ячейками крайних позиций таблицы.
			for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
				if (cellsGrid[rowIndex] === undefined) {
					cellsGrid[rowIndex] = [];
				}
				if (cellsGrid[rowIndex][columnIndex] === undefined) {
					cells[rowIndex][columnIndex] = this.getDefaultCellTexture(
						this.EMPTY_CELL_VALUE,
						rowIndex,
						columnIndex,
					);
				}
			}
		}
	};

	/**
	 * Возвращает модель текста по умолчанию.
	 * @param content Текст модели.
	 */
	private getDefaultModel = (content: string): Model => ({
		id: Utils.Generate.UUID4(),
		lineHeight: 1,
		tokens: [
			{
				type: TokenType.Text,
				value: content,
				color: '#000000',
				format: 0o00000100,
				fontSize: FontSize.Pt12,
				textAlign: TextAlign.LEFT,
				fontFamily: FontFamily.Default,
				lineHeight: 1,
				sticky: true,
			},
		],
	});

	/**
	 * Вычисляет параметры инжекции для компонента по позиции мыши.
	 * @param mousePosition Текущая позиция мыши.
	 */
	protected calculateComponentInjectionParams = (
		mousePosition: IDescartesPosition,
	): IComponentInjectionParams => {
		// Переменная для хранения ближайшей страницы
		let nearestPage: IGraphic | null = null;
		// Переменная для хранения минимального расстояния
		let minDistance = Number.MAX_SAFE_INTEGER;

		// Получаем список страниц
		const treeRootGraphics = this.dependencies.componentTree.getRootGraphics();
		// Находим ближайшую страницу к области вставки компонента
		for (let i = 0; i < treeRootGraphics.length; i++) {
			const rootGraphic = treeRootGraphics[i];

			const { y, height } = rootGraphic.getFrameConfiguration();
			const pageCenterY = y + height / 2;
			// Вычисляем расстояние от текущей позиции мыши до центра текущей страницы
			const distance = Math.abs(mousePosition.y - pageCenterY);
			// Обновляем ближайшее страницу и минимальное расстояние
			if (distance < minDistance) {
				minDistance = distance;
				nearestPage = rootGraphic;
			}
		}

		if (nearestPage === null) {
			throw new ManipulatorError('nearest page not found');
		}

		const graphicPosition: IDescartesPosition = nearestPage.getGlobalPosition();
		const componentOffset: number = nearestPage.getOffset();
		return {
			x: graphicPosition.x,
			y: graphicPosition.y,
			componentOffset,
		};
	};
}

export default HTMLTableImporter;
