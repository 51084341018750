import { useState, useEffect, useCallback } from 'react';
import { adminAPI } from '../../entities/admin/api/api';
import { IAdminUsersFilterItem } from '../../components/pages/admin-panel/users/models/filter-data';
import { IAdminAccountsItem } from '../../entities/admin/api/types';

const ITEM_LIMIT_PER_PAGE = 40;
const PRELOAD_THRESHOLD = 400;

export const useAdminUsersList = (search: string, filter: IAdminUsersFilterItem) => {
	const [users, setUsers] = useState<IAdminAccountsItem[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(1);

	const [getAccounts, { data, isFetching }] = adminAPI.useLazyGetAccountsQuery();

    /**
     * Функция для получения аккаунтов
     */
	const fetchAccounts = useCallback(() => {
		getAccounts({
			search,
			page,
			limit: ITEM_LIMIT_PER_PAGE,
			filterBy: filter.id,
		});
	}, [search, page, filter.id, getAccounts]);

    /**
     * Выполняет начальный запрос при изменении поиска или фильтра
     */
	useEffect(() => {
		if (page === 1) {
			fetchAccounts();
		}
	}, [search, filter, page, fetchAccounts]);

    /**
     * Обновляет список пользователей при получении новых данных
     */
	useEffect(() => {
		if (data?.accounts) {
			setUsers((prevUsers) => [...prevUsers, ...data.accounts]);
			if (data.accounts.length < ITEM_LIMIT_PER_PAGE) {
				setHasMore(false);
			}
		}
	}, [data]);

    /**
     * Ограничивает частоту вызова функции
     */
	const debounce = (func: (...args: any[]) => void, delay: number) => {
		let timeoutId: NodeJS.Timeout;
		return function (this: any, ...args: any[]): void {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => func.apply(this, args), delay);
		};
	};

    /**
     * Обработчик прокрутки для загрузки дополнительных данных
     */
	const loadMoreOnScroll = useCallback(debounce(() => {
		const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

		if (scrollTop + clientHeight >= scrollHeight - PRELOAD_THRESHOLD && hasMore && !isFetching) {
			setPage((prevPage) => {
				const newPage = prevPage + 1;
				return newPage;
			});
		}
	}, 100), [hasMore, isFetching]);

    /**
     * Выполняет запрос при изменении страницы
     */
	useEffect(() => {
		if (page > 1) {
			fetchAccounts();
		}
	}, [page, fetchAccounts]);

    /**
     * Добавляет и удаляет обработчик прокрутки
     */
	useEffect(() => {
		window.addEventListener('scroll', loadMoreOnScroll);
		return () => window.removeEventListener('scroll', loadMoreOnScroll);
	}, [hasMore, loadMoreOnScroll]);

	return { users, hasMore, isFetching };
};
